import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author {
              name
              summary
              href
            }
            siteUrl
            siteImage {
              image
              imageAlt
              imageTitleHtml
            }
            navigation {
              header {
                name
                href
              }
              menu {
                name
                href
              }
              learning {
                name
                href
              }
              #reviews {
              #  name
              #  href
              #}
              site {
                name
                href
              }
              social {
                name
                href
              }
            }
            social {
              Twitter
              LinkedIn
              GitHub
              #Facebook
            }
            #analytics {
            #  fbPageId
            #  fbAppId
            #}
          }
        }
      }
    `,
  );

  return { siteMetadata: site.siteMetadata };
};
