import * as React from 'react';
import { Component } from 'react';

import CookieConsent from './cookie-consent';

class FooterNewsletter extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="pt-8 border-t border-gray-700 lg:flex lg:items-top lg:justify-between xl:mt-0">
        <div className="mt-4 sm:flex">
          <div className="grid grid-cols-1 auto-rows-min">
            <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
              <a
                className="text-blue-500"
                href="https://mihaibojin.medium.com/subscribe"
                target="_blank"
                rel="noreferrer"
              >
                Subscribe to my newsletter!
              </a>
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              I promise I won't spam you.
            </p>
          </div>
        </div>

        <CookieConsent />
      </div>
    );
  }
}

export default FooterNewsletter;
