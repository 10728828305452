import * as React from 'react';
import { Link } from 'gatsby';

import FooterNewsletter from './footer-newsletter';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import logos from './logo';

export default function Footer() {
  const { siteMetadata } = useSiteMetadata();

  const navigation = siteMetadata.navigation;
  const social = siteMetadata.social;

  return (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Menu
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.menu.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        title={item.name}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Learning
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.learning.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        title={item.name}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {navigation.reviews && (
                <div>
                  <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                    Reviews
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.reviews.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          title={item.name}
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Site
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.site.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        title={item.name}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <FooterNewsletter />
        <div className="pt-8 mt-8 border-t border-gray-700 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => {
              const Logo = logos[item.name];

              return (
                <a
                  key={item.name}
                  href={item.href + social[item.name]}
                  title={item.name + ': ' + social[item.name]}
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <Logo className="w-6 h-6" aria-hidden="true" />
                </a>
              );
            })}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()}, Built with ❤️ and
            {` `}
            <a href="https://www.gatsbyjs.com" title="GatsbyJS">
              Gatsby
            </a>
            . Opinions my own.
          </p>
        </div>
      </div>
    </footer>
  );
}
