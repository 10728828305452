import * as React from 'react';
import { Link } from 'gatsby';

import { SpeakerphoneIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/outline';

import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import { getValue, setValue, isBrowser } from '../services/consent';

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = () => {
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden',
  );

  const [localHide, setLocalHide] = React.useState(false);

  // address Gatsby rehydration problems
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  const HideBanner = () => {
    // setBannerHidden(true);
    setLocalHide(true);
  };

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    document.cookie = 'gatsby-gdpr-google-tagmanager=true';
    document.cookie = 'gatsby-gdpr-facebook-pixel=true';
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && !localHide && (
        <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
          <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="p-2 bg-indigo-600 rounded-lg shadow-lg sm:p-3">
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex items-center flex-1 w-0">
                  <span className="flex p-2 bg-indigo-800 rounded-lg">
                    <SpeakerphoneIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <p className="ml-3 font-medium text-white truncate">
                    <span className="md:hidden">
                      This website uses cookies.{' '}
                      <Link
                        to="/privacy-policy"
                        title="Privacy Policy"
                        className="font-bold text-white underline"
                      >
                        Learn more{' '}
                        <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </span>
                    <span className="hidden md:inline">
                      We use cookies to personalize content and
                      analyze our traffic.{' '}
                      <Link
                        to="/privacy-policy"
                        title="Privacy Policy"
                        className="font-bold text-white underline"
                      >
                        {' '}
                        Learn more{' '}
                        <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </span>
                  </p>
                </div>
                <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    onClick={EnableAnalytics}
                    className="flex items-center justify-center flex-auto w-full px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-50"
                  >
                    OK
                  </button>
                </div>

                <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
                  <button
                    type="button"
                    onClick={HideBanner}
                    className="flex p-2 -mr-1 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
