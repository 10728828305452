import { useStaticQuery, graphql } from 'gatsby';
import { getSrc, getImage } from 'gatsby-plugin-image';

const siteImage = require('../../config/site-image');

export const useSiteImage = () => {
  const { img } = useStaticQuery(
    graphql`
      query SiteImage {
        img: file(relativePath: { eq: "default.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return {
    siteImage: {
      image: getImage(img),
      src: getSrc(getImage(img)),
      imageAlt: siteImage.imageAlt,
      imageTitleHtml: siteImage.imageTitleHtml,
    },
  };
};
