import { getImage, getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteImage } from '../hooks/use-site-image';
import { useSiteMetadata } from '../hooks/use-site-metadata';

function Seo({
  title,
  description,
  date,
  lastUpdated,
  tags,
  image,
  canonicalURL,
  absoluteURL,
  lang,
}) {
  let meta = [];
  let links = [];

  const { siteMetadata } = useSiteMetadata();
  const { siteImage } = useSiteImage();
  //
  const pageDescription = description || siteMetadata.description;

  // define card image
  // TODO: clean this up, make image required
  const img = image?.image ? getImage(image.image) : siteImage;
  const imgSrc = image?.image ? getSrc(img) : img.src;
  meta.push({
    property: `twitter:image`,
    content: siteMetadata.siteUrl + imgSrc,
  });
  meta.push({
    property: `og:url`,
    content: absoluteURL || canonicalURL,
  });

  meta.push({
    property: `og:image`,
    content: siteMetadata.siteUrl + imgSrc,
  });
  meta.push({
    property: `og:updated_time`,
    content: lastUpdated,
  });
  meta.push({
    property: 'og:image:width',
    content: parseInt(img.width),
  });
  meta.push({
    property: 'og:image:height',
    content: parseInt(img.height),
  });

  if (siteMetadata?.analytics) {
    meta.push({
      property: 'fb:pages',
      content: siteMetadata?.analytics.fbPageId,
    });
    meta.push({
      property: 'fb:app_id',
      content: siteMetadata?.analytics.fbAppId,
    });
  }

  if (siteMetadata?.social?.Twitter) {
    meta.push({
      name: `twitter:creator`,
      content: siteMetadata?.social?.Twitter
        ? '@' + siteMetadata?.social.Twitter
        : ``,
    });
    meta.push({
      name: `twitter:site`,
      content: siteMetadata?.social?.Twitter
        ? '@' + siteMetadata?.social.Twitter
        : ``,
    });
    meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    });

    // define card image alt
    if (image?.imageAlt) {
      meta.push({
        name: `twitter:image:alt`,
        content: image.imageAlt,
      });
      meta.push({
        name: `og:image:alt`,
        content: image.imageAlt,
      });
    }
  }

  if (tags) {
    meta.push({
      name: 'keywords',
      content: tags.join(','),
    });
  }

  if (canonicalURL) {
    links.push({
      rel: 'canonical',
      href: canonicalURL,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: 'og: http://ogp.me/ns#',
      }}
      title={title}
      titleTemplate={
        siteMetadata?.title ? `%s | ${siteMetadata.title}` : `%s`
      }
      link={links}
      meta={[
        {
          name: `description`,
          content: pageDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: pageDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: pageDescription,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  tags: [],
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string,
  lastUpdated: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  canonicalURL: PropTypes.string,
  absoluteURL: PropTypes.string,
  lang: PropTypes.string,
};

export default Seo;
